.articleCard > .ant-card-cover{
    padding: 0;
}

.articleCard > .ant-card-cover img{
    max-width: 100%;
}

.articleCard > .ant-card-meta-title{
    font-size: 20px;
    font-weight: bold;
}

.article_widget{
    padding: 15px;
}

.article_body{
    padding: 50px;
    overflow-wrap: break-word;
    font-size: 16px;
    text-align: justify;
    border-right: 1px solid #E68D21;
}
