.langSelect{
    padding-left: 15px;
    width: 70px;
}

.langSelect > div{
    background-color: #ffffff !important;
    border: none !important;
}

.iconFlag{
    max-width: 50px;
}
