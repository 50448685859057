html {
    scroll-behavior: smooth;
}

.headerCont{
    position: fixed; 
    z-index: 1; 
    width: 100%;
    background: #ffffff;
}

.site-layout{
    background: white;
}

.menu{
    background: transparent;
    border-bottom: none !important;
}

.ant-menu-item-selected, .ant-menu-submenu-selected{
    color: white !important;
    background: #E68D21 !important; /*Color item selected navbar*/
    border-bottom: 2px solid #E68D21 !important; /*Border Color item selecter*/
}

.ant-menu-item-selected > a{
    color: #ffffff !important; /*Color navbar letras opción seleccionada*/
}

.ant-menu-submenu-selected > .ant-menu-submenu-title{
    color: #ffffff !important;
}

.ant-menu-horizontal > .ant-menu-item a, .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title{
    color: #E68D21; /*color letras navbar*/
}

.ant-menu-item, .ant-menu-submenu-title{
    color: #E68D21;
    /*font-weight: bold;*/
}

.ant-menu-item:hover, .ant-menu-submenu:hover{
    background: transparent !important;
    border-bottom: 2px solid #E68D21 !important; /*hover navbar border*/
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #E68D21;
    border-bottom: 2px solid #E68D21;
}

.ant-menu-item > a:hover, .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title:hover{
    color: #E68D21 !important; /*hover navbar letras*/
}

.full_width{
    width: 100%;
}

.text_center{
    text-align: center;
}

.text_right{
    text-align: right;
}

.title{
    text-align: center;
    color: #E68D21;
}

.filter_field{
    padding: 5px;
}

.with_space{
    margin-bottom: 10px;
}

/* .ant-select-dropdown{ */
    /* width: 300px !important; */
/* } */

.ant-layout-header{
    padding: 0;
}

#banner{
    overflow: hidden;
    margin-top: -22px;
    height: 62vh;
}

/* #banner > img{
    height: 600px;
} */

.index_section{
    padding: 40px 50px 15px 50px;
    width: 100%;
}

.service_card{
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    margin-bottom: 10px;
}

.service_card > .ant-card-head{
    text-align: center;
    color: white;
    font-weight: bolder;
    font-size: x-large;
}

.service_card > .ant-card-body{
    background-color: white;
    -webkit-border-bottom-right-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    height: inherit;
    padding: 15px 0 0 0!important;
}

.light_bg{
    background-color: #41a9bd;
}

.us_bg{
    background-color: #E9E7E4;
}

.dark_bg{
    background-color: #E68D21;
}

.light_border{
    border-color: #41a9bd;
}

.dark_border{
    border-color: #E68D21;
}

.title, .title_dark, .title_light, .title_white{
    text-align: center;
    font-size: 30px;
    padding: 15px;
    width: 100%;
}

.title_dark{
    color: #E68D21;
}

.title_light{
    color: #03728C;
}

.title_white{
    color: white;
}

.ind_light{
    color: #E68D21;
}

.bg_grey{
    background-color: #f0f2f5;
}

.layout_padding{
    padding: 15px 50px 15px 50px;
}

.logo{
    text-align: center;
}

.logo > a > img{
    width: 54px;
}

#we_desc{
    color: #2D0B0B;
    font-size: 24px;
    padding: 15px;
}

.statistic_card{
    width: 80%;
    height: 80%;
    background-color: #f0f2f5;
    text-align: center;
    padding: 15px;
    margin-bottom: 10px;
}

.statistic_card > h1{
    font-size: xxx-large;
}

.ant-layout-footer{
    background-color: #E9E7E4;
    color: white;
    width: 100%;
}

.price_button_light, .price_button_dark{
    color: white;
    -webkit-border-bottom-right-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: large;
    font-weight: bold;
    padding: 10px;
    height: fit-content;
}

.price_button_dark:hover{
    color: #E68D21;
    border-color: #E68D21;
}

#price_tag{
    position: absolute;
    right: -10%;
    top: -10%;
    width: 30%;
    height: 42%;
    background-color: crimson;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transform: rotate(25deg);
    color: white;
}

#price_tag > span{
    text-decoration: line-through;
}

.social_icon{
    font-size: 30px;
    padding: 10px;
    color: white;
}

.footer_links{
    width: 100%;
    text-align: center;
}

.footer_links > a{
    color: #2D0B0B;
}

.responsive {
    width: 100%;
    height: auto;
  }

.text-right{
    text-align: right;
}

.bottomBar{
    margin: 15px;
}

.lastWeekList{
    max-height: 40vh;
    overflow: auto;
    border: solid 1px #E68D21;
}

.lastWeekRow{
    color: white;
    padding-left: 15px;
}

.lastWeekList > div > div > div > div:nth-child(even){
    background-color: #E9E7E4; /*Color table spacing*/
}

.lastWeekRow > div > a > h2{
    color: #E68D21;
    margin: 0;
}

.lastWeekRow > div:nth-child(2){
    padding-top: 5px;
}

.lastWeekList > div > div > div{
    margin: 0 !important;
}

.lastWeekList > div > div > div > div > div > div{
    margin-bottom: 0 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.imgCont{
    padding-top: 15px;
}

#coverMsg{
    font-size: 50px;
    color: white;
    position: absolute;
    top: 15vh;
    font-weight: bold;
    width: 49%;
    padding-left: 5%;
}

.allLink{
    position: absolute;
    right: 50px;
    top: 0px;
}

#add1{
    padding: 15px;
}

.btn-flotante {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 35px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #E68D21; /* Color de fondo */
	padding: 10px 25px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
/* @media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
} */