.line_bottom{
    border-bottom: solid 1px gray;
}

.line_right{
    border-right: solid 1px gray;
}

.line_left{
    border-left: solid 1px gray;
}

.tree_margin_1{
    margin-left: -2%;
}

.tree_margin_2{
    margin-left: 4%;
}

.tree_margin_3{
    margin-left: -4%;
}

@media only screen and (max-width: 600px) {
    .tree_margin_1, .tree_margin_2, .tree_margin_3{
        margin-left: 0%;
    }
}

.pedigree_column{
    vertical-align: middle;
    min-height: 100px;
    padding: 10px;
}

th{
    text-align: center;
}