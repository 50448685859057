.ant-card-cover{
    padding: 15px;
}

.ant-card-cover img{
    max-width: 120px;
    display: block;
    margin: auto;
}

.ant-card-meta-title > center > a{
    white-space: break-spaces;
}

.ant-card-body{
    padding: 5px !important;
    /* height: 50px; */
}

.female_card {
    border: 2px solid pink !important
}

.female_card > .ant-card-cover img{
    transform: scaleX(-1);
}

.male_card {
    border: 2px solid blue !important
}

.void_card{
    height: 95px;
    background-color: #fafafa;
}

.numberCard > .ant-card-head{
    text-align: center;
    font-size: 24px;
    height: 95px;
}

.numberCard > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title{
    white-space: pre-line !important;
}

.numberCard > .ant-card-body{
    text-align: center;
    font-size: 36px;
    padding: 20px !important;
    height: auto !important;
}